<template>
  <div>
    <!-- form表单 -->
    <el-form v-if="!disabled" ref="nextForm" :model="nextForm" inline style="display: flex;align-items: center;flex-wrap: wrap;">
      <!-- 平台 -->
      <el-form-item :label="$t('title.platform')">
        <el-select v-model="nextForm.platform" collapse-tags clearable filterable :placeholder="$t('page.selectPlaceholder')" @change="platformChange">
          <el-option :label="$t('title.All')" value="">{{ $t('title.All') }}</el-option>
          <el-option
            v-for="item in platformList"
            :key="item.id"
            :label="item.platformCode"
            :value="item.platformCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('title.SellingAccount')">
        <el-select v-model="nextForm.account" class="addWidth" collapse-tags clearable filterable @change="PlatformAccountChange">
          <el-option :label="$t('title.All')" value="">{{ $t('title.All') }}</el-option>
          <el-option
            v-for="item in PlatformAccountList"
            :key="item.id"
            :label="item.account"
            :value="item.account"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('title.platforms')">
        <el-select v-model="nextForm.site" class="addWidth" clearable filterable>
          <el-option :label="$t('title.All')" value="">{{ $t('title.All') }}</el-option>
          <el-option
            v-for="item in terraceData"
            :key="item.id"
            :label="item.platformSite"
            :value="item.platformSite"
          />
        </el-select>
      </el-form-item>
      <!-- BU -->
      <el-form-item label="BU">
        <el-select v-model="nextForm.bu" class="addWidth" value-key="id" collapse-tags multiple filterable>
          <el-option
            v-for="item in buOptions"
            :key="item.id"
            :label="item.bu"
            :value="item.bu"
          />
        </el-select>
      </el-form-item>
      <div style="margin-bottom: 22px;white-space: nowrap;vertical-align: top;display: inline-block;">
        <!-- <el-button v-if="!isIframe" class="ml-3" type="primary" :loading="sendLoading" size="mini" @click="closeOrder()">{{ $t('title.Closepage') }}</el-button> -->
        <el-button v-if="basicInfo.platform === 'STANDWEBSITE' " class="ml-3" type="primary" size="mini" @click="insertShopify()">{{ $t('title.Blockedmail') }}</el-button>
      </div>
    </el-form>
    <!-- 只有在工单质检管理跳转过来的时候才显示 -->
    <div v-if="disabled" style="margin-bottom: 16px;">
      <span class="mr-3">{{ $t("title.Ticketno") }}：{{ basicInfo.workNumber }}</span>
    </div>
    <!-- 最新消息弹出框 -->
    <div style="display: flex; justify-content: space-between">
      <span>
        <span class="mr-3">{{ $t("title.platform") }}：{{ basicInfo.platform }}</span>
        <span class="mr-3">{{ $t("title.number") }}：{{ basicInfo.account }}</span>
        <span class="mr-3">{{ $t("title.Customerids") }}：{{ basicInfo.messageSender }}</span>
        <!-- <p>主题: {{basicInfo.messageDetailVOList[0].subject}}</p> -->
      </span>
      <span>
        <!-- <el-button v-if=" basicInfo.messageStatus === 1 || basicInfo.messageStatus === 2 " type="primary" size="small" :disabled=" basicInfo.messageStatus === 1 || basicInfo.messageStatus === 2 ">{{ $t("title.replied") }}</el-button> -->
        <!-- <el-button type="primary" size="small" @click="handlenextMessage(0)">上一封</el-button> -->
      </span>
    </div>
    <p> {{ $t("title.Subjectmail") }}:{{ basicInfo.messageText }} </p>
    <el-row v-if="!basicInfo.subject" style="margin-bottom: 10px;">{{ basicInfo.subject }}</el-row>
    <el-row type="flex" justify="space-between">
      <!-- 聊天窗口 -->
      <!-- <div style="width: 20%">
          <span>消息预览</span>
          <div style="height:550px;overflow-y: scroll;">
            <ul style="list-style: none;padding:0;margin:0;border-bottom: 1px solid #e1e1e1;border-left: 1px solid #e1e1e1;border-right: 1px solid #e1e1e1;">
              <li v-for="(items, index) in historyData" :key="index" style="border-top: 1px solid #e1e1e1;padding:8px;cursor:pointer;" :class="{ active: index === changeIndex }" @click="handleHistoryData(items.id,index)">
                <p style="display: flex; justify-content: space-between;">
                  <span>{{ items.messageSender }}</span>
                  <span>{{ items.createTime }}</span>
                </p>
                <p>{{ items.messageText }}</p>
              </li>
            </ul>
          </div>
        </div> -->
      <el-row style="width: 70%">
        <div class="wrap" :style="faceShow ? 'min-height: 606px' : 'min-height:400px'" style="position: relative;">
          <!-- 中间 聊天内容区域 -->
          <div ref="singleHeight" class="main">
            <ul v-for="(item, index) in customerMessageList" ref="ul" :key="index" class="talk_list">
              <li>
                <!-- 左边 -->
                <div v-if="item.type === 1&&!disabledFlag" :class="{'left_word':!disabledFlag,'Left_Word':disabledFlag}">
                  <p style="padding-left:10px"><span style="color:#000">{{ basicInfo.messageSender }}</span><span style="display:inline-block;padding-left:10px">{{ item.createdTime }}</span> </p>
                  <div v-if="item.body || (item.mediaList && item.mediaList.length)" style="white-space: pre-wrap;"> {{ item.body }}<br>
                    <span v-for="(ITEM, ind) in item.mediaList" :key="ind">
                      <el-image v-if="ITEM.mediaType.includes('image')" :src="ITEM.mediaURL" :preview-src-list="getSrcList(index, item.mediaList)" />
                      <el-link v-else type="primary" :href="ITEM.mediaURL" target="_blank"> {{ ITEM.mediaURL }}</el-link>
                    </span>
                    <el-button v-if="item.noResponseNeeded && basicInfo.workStatus !== 99" type="text" size="small" :disabled="disabled" @click="noReply(item)">No response needed</el-button>
                  </div>
                </div>
                <!-- 左边,目前看来没有组件传disabledFlag,所以这里不会展示了 -->
                <div v-else-if="item.type === 1 && disabledFlag" :class="{ left_word: !disabledFlag, Left_Word: disabledFlag, }">
                  <div v-if="item.body" v-html="item.body" />
                  <el-button v-if="item.noResponseNeeded && basicInfo.workStatus !== 99" type="text" size="small" :disabled="disabled" @click="noReply(item)">No response needed</el-button>
                </div>
                <!-- 右边 -->
                <div v-else-if="item.type === 2">
                  <p style="text-align:right;padding-right:10px;"><span style="color:#666">{{ item.operatorName }}</span><span style="display:inline-block;padding-left:10px">{{ item.createdTime }}</span> </p>
                  <div class="right_word">
                    <span v-if="item.body" style="word-break: break-word;" v-html="item.body" />
                    <span v-if="item.mediaList && item.mediaList.length">
                      <img
                        v-for="(ITEM, indexs) in item.mediaList"
                        :key="indexs"
                        :src="ITEM.mediaURL"
                        style="width:50px;height:50px;"
                      >
                    </span>
                    <el-button v-if="item.noResponseNeeded && basicInfo.workStatus !== 99" type="text" size="small" :disabled="disabled" @click="noReply(item)">No response needed</el-button>
                  </div>
                </div>
                <!-- No response needed -->
              </li>
            </ul>
            <!-- <p type="text" style="text-algin:center;position:absolute;top: 335px;left:50%;transform: translateX(-50%);z-index: 99;cursor:pointer;color:	#6495ed;margin: 0;" :disabled="disabled" @click="allMeassge">{{ $t("title.Allmessages") }}</p> -->
          </div>
          <!-- 底部 消息编辑区域 -->
          <div v-if="!disabled">
            <el-input id="textarea" ref="input" v-model="content" autofocus="autofocus" :rows="6" type="textarea" :placeholder="$t('title.EnterSend')" @keydown.enter.native="keyDown" />
            <div class="footer">
              <el-checkbox v-model="checked" class="mr-3">{{ $t("title.Autonextmessage") }}</el-checkbox>
              <i class="el-icon-s-custom ml-3" @click="faceContent" />
              <el-upload
                ref="upload"
                style="display: inline-block;"
                :headers="uploadHeaders"
                :action="action"
                :before-upload="beforeAvatarUpload"
                :on-success="handleSuccess"
              >
                <i class="el-icon-picture-outline ml-3" />
              </el-upload>
              <el-dropdown @command="nikeName">
                <span class="el-dropdown-link ml-3">
                  {{ $t("title.Insert") }}<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="客户昵称">{{ $t("title.Customerid") }}</el-dropdown-item>
                  <el-dropdown-item command="账号名称">{{ $t("title.Account") }}</el-dropdown-item>
                  <el-dropdown-item command="客服名称">{{ $t("title.Agentname") }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <span class="ml-3" style="cursor:pointer;" @click="nikeName">插入客户昵称</span> -->
              <el-button class="ml-3" type="primary" :loading="sendLoading" size="mini" @click="allsendMsg">  {{ $t("title.Closeworkorder") }}</el-button>
              <el-button class="ml-3" type="primary" :loading="sendLoading" size="mini" @click="sendMsg(1)">
                {{ $t("title.send") }}</el-button>
              <el-button type="primary" size="mini" @click="handlenextMessage(1)">{{ $t("title.nextMessage") }}</el-button>
              <el-button type="primary" :loading="sendLoading" size="mini" @click="handlenextMessageStop()">{{ $t('tips.Noreply') }}</el-button>
              <!-- 表情区域 -->
              <div v-if="faceShow" class="browBox">
                <ul>
                  <li v-for="(item, index) in faceList" :key="index" @click="getBrow(index)">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!disabled" style="margin-top:10px;display: flex;align-items: center;">
          <div style="font-size:16px;display: inline-block;margin-right: 20px;">{{ $t("title.Template") }}</div>
          <el-button type="primary" class="el-icon-refresh" @click="_getTemplatePersonByUserId()">刷新</el-button>
        </div>
        <el-row v-if="!disabled" style="width: 50%;margin-top: 10px;">
          <div><el-input v-model="inputContent" :placeholder=" $t('tips.enterContent') " clearable @input="getContent" /></div>
          <div v-for="(i,index) in contentData" :key="index">
            <p style="cursor:pointer;" @click="handleContent(i.templateContent)">{{ i.templateContent }}</p>
          </div>
        </el-row>
        <el-tabs v-if="!disabled" v-model="templateType" style="margin-top: 10px;">
          <el-tab-pane v-for="(item,index) in templateData" :key="index" :label="item.templateType">
            <div style="display: flex;justify-content:space-between;">
              <div style="width:50%">
                <p>{{ $t("title.Producttitle") }}</p>
                <div v-for="(items,ind) in templateData[index].templateList" :key="ind" style="margin: 15px 0;padding:5px 0;line-height: 26px;height: 52px;">{{ items.templateTitle }}</div>
              </div>
              <div style="width:50%">
                <p>{{ $t("title.Preview") }}</p>
                <div v-for="(items,ind) in templateData[index].templateList" :key="ind" :title="items.templateContent" style="margin: 15px 0;padding:5px 0;cursor:pointer;height: 53px;line-height: 26px;" @click="handleContent(items.templateContent)">{{ items.templateContent | ellipsis }}</div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <el-row class="ml-3" style="width: 30%">
        <OrderInfo
          ref="orderinfo"
          :basic-info="basicInfo"
          :order-info="OrderInfo"
          :note-lists="noteLists"
          :dialog-visible="dialogVisible"
          @getfocus="getfocus"
          @handleRefresh="_getAmazonPagedList"
        />
      </el-row>
    </el-row>
  </div>
</template>

<script>
import OrderInfo from '../buyer-email/components/OrderInfo'
import {
  getMessageInfoByCondition,
  getTemplateContent,
  getTemplatePersonByUserId,
  // getAmazonPagedList,
  batchUpdateMessageWorkStatus,
  // getAllLogByMessageId,
  // batchAmazonUpdateMessageStatus,
  // sendmail,
  // batchUpdateMessageWarnById,
  updateAmazonMessageReadById,
  getLogByMessageId,
  mailaccountinfo,
  querySiteList,
  batchSendMessage,
  allBU,
  updateMessageServiceIsWorkingById,
  insertShopifyShieldEmail,
  checkSensitiveWord,
  getMailAccountAll,
  getMessageById
} from '@/api/service-management'
// import { deepClone } from '@/utils'
import { queryTerraceList } from '@/api/omsorder'
// import store from '@/store'
import mixin from '@/mixin/oms-mixin'
// import { trimLeft } from 'xe-utils/methods'
// import Editor from '@/components/quil-editor'
const appData = require('@/views/service-manage/emoji.json')

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

export default {
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 38) {
        // 判断字段
        return value.slice(0, 38) + '...'
      }
      return value
    }
  },
  components: {
    OrderInfo
  },
  mixins: [mixin],
  props: {
    // 没用到
    form: {
      type: Object,
      default: () => ({})
    },
    // 没用到，一直为false
    disabledFlag: {
      type: Boolean,
      default: false
    },
    // 没用到
    tableLoading: {
      type: Boolean,
      default: false
    },
    // 没用到
    tableHeight: {
      type: String,
      default: ''
    },
    // 没用到
    tableData: {
      type: Array,
      default: () => []
    },
    // 没用到
    pager: {
      type: Object,
      default: () => ({})
    }
    // terraceData: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      templateType: 0,
      sellerFileList: [],
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      action: process.env.VUE_APP_UPLOAD_API,
      radio1: 0,
      sendLoading: false,
      OrderInfo: [],
      dialogVisible: false,
      basicInfo: {},
      multipleSelection: [],
      ids: [],
      threadListMarkasreplied: [],
      checked: false,
      platformList: [], // 平台条件查询
      content: '',
      customerMessageList: [],
      faceList: [],
      faceShow: false,
      getBrowString: '',
      imgs: [],
      noteLists: [],
      historyData: [],
      templateData: [],
      contentData: [],
      inputContent: '',
      temp: null, // 倒计时初始
      changeIndex: ' ',
      defaultText: '',
      // 富文本更新的值
      richText: '',
      nextForm: {
        platform: '',
        account: '',
        site: '',
        bu: ''
      },
      buOptions: [],
      terraceData: [],
      PlatformAccountList: [],
      workName: '',
      // tableData: []

      // 工单质检管理跳转过来的时候，页面只供展示不可操作
      disabled: Boolean(this.$route.query.disabled) || false,

      // 这个工单有订单号，且这个订单号是亚马逊的，且这个订单号下有多个（超过1个）未完结的工单
      messageInstance: null
    }
  },
  watch: {
    // 感觉不会经过这里
    dialogVisible(val) {
      if (!val) {
        this.faceShow = false
        this.checked = false
        this.$refs.orderinfo.noteList = []
        this.basicInfo.messageMarkLabelList &&
        this.basicInfo.messageMarkLabelList.length
          ? (this.basicInfo.messageMarkLabelList[0].markLabel = '')
          : ''
        this.OrderInfo ? (this.OrderInfo = []) : ''
      }
    }
  },
  created() {
    // 没啥用
    // 等待组件加载完毕赋值
    this.$nextTick(() => {
      this.defaultText = `<p></p>`
    })
    // window.addEventListener('beforeunload', e => this.updateHandler(e))
  },
  mounted() {
    // 页面数据
    this._getAmazonPagedList()
    // 平台
    this._queryTerraceList()
    // BU
    this._allBU()
    // 站点
    this._querySiteList()
    // this.getmailaccountinfo('STANDWEBSITE')
    window.addEventListener('onunload', e => {
      this.updateHandler(e)
    })
  },
  destroyed() {
    // 切记页面销毁需要销毁
    clearInterval(this.temp)
    // window.removeEventListener('beforeunload', e => this.updateHandler(e))
    window.removeEventListener('onunload', e => {
      this.updateHandler(e)
    })
  },
  methods: {
    // 这个工单有订单号，且这个订单号是亚马逊的，且这个订单号下有多个（超过1个）未完结的工单
    amazonHasMultipleOrder() {
      // 判断这个字段的时候后端已经加上了这个逻辑
      if (this.basicInfo.otherWorkNumber === 1) {
        const url = this.$router.resolve({
          path: '/service-manage/Buyer-mail-message/buyer-email',
          query: {
            orderCoder: this.basicInfo?.orderCoder
          }
        })
        this.messageInstance = this.$message({
          type: 'warning',
          duration: 30000,
          dangerouslyUseHTMLString: true,
          message: `<span>该订单号下存在多个工单，请<a style="color: #409EFF" target="_blank" href="${url.href}">点击</a>查看</span>`
        })
      }
    },
    // Amazon 暂不回复
    noReply(row) {
      this.$confirm('是否确认不回复此消息', '暂不回复', {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(async() => {
        try {
          const { code } = await batchUpdateMessageWorkStatus({
            ids: this.basicInfo.id,
            status: 99
          })
          if (code === 0 && row.noResponseNeeded) {
            window.open(row.noResponseNeeded)
            this.handlenextMessage(1)
          }
        } catch (err) {
          console.log(err)
        }
      })
    },
    // 获取平台
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platformList = datas
      this.platformList = this.platformList.filter((item) => {
        return item.platformCode === 'amazon' ||
        item.platformCode === 'ebay' ||
        item.platformCode === 'walmart' ||
        item.platformCode === 'Shopify' ||
        item.platformCode === 'standwebsite' ||
        // sb正式测试不一样
        item.platformCode === 'Tik Tok' ||
        item.platformCode === 'TikTok'
      })
    },
    // 平台改变
    platformChange() {
      this.getMailAccountAll(this.nextForm.platform ? this.nextForm.platform : '-1')
      this._allBU()
      this.form.account = ''
      this.form.site = ''
    },
    // 获取平台账号
    async getMailAccountAll(platform) {
      const { datas } = await getMailAccountAll(platform)
      datas.length
        ? (this.terraceData = datas[0].siteList)
        : (this.terraceData = [])
      this.PlatformAccountList = datas
      // datas.map(e => {
      //   this.terraceData = e.siteList
      // })
    },
    // 更新工单是否有其他人在处理,isWorking 0:不在处理,1:在处理
    updateHandler(e) {
      this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 0 })
    },
    async _allBU() {
      const { datas } = await allBU()
      // this.buOptions = datas
      this.buOptions = []
      datas.map(e => {
        const index = this.buOptions.findIndex((value) => value.bu === e.bu)
        // 有两个没有bu字段的数据
        index === -1 && e.bu ? this.buOptions.push(e) : ''
      })
      // 还得手动加上bu为空的字段查询
      this.buOptions.push({ id: 'empty', bu: '空' })
    },
    // 屏蔽邮箱
    insertShopify() {
      this.$confirm('屏蔽后,将无法收到此邮箱发送过来的邮件,是否确认操作!', '屏蔽邮箱', {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(() => {
        const { messageDetailVOList, messageSender, account } = this.basicInfo
        const data = Object.assign({}, { account, emailAddress: messageDetailVOList[0].senderId, name: messageSender })
        this._insertShopifyShieldEmail(data)
      })
    },
    async _insertShopifyShieldEmail(row) {
      const { msg } = await insertShopifyShieldEmail(row)
      this.$notify({
        title: msg,
        message: msg,
        type: 'success'
      })
    },
    // 平台站点
    async _querySiteList() {
      const { datas } = await querySiteList({ sellPlatformId: 8 })
      this.terraceData = datas
    },
    // 没有用到
    async getmailaccountinfo(platform) {
      const { data } = await mailaccountinfo(platform)
      this.PlatformAccountList = data
    },
    // 平台账号改变
    PlatformAccountChange(val) {
      this.nextForm.account = val
      const value = this.PlatformAccountList.find(e => e.account === val)
      this.nextForm.site = ''
      this.terraceData = value.siteList
    },
    // 获取所有使用模板
    async _getTemplatePersonByUserId() {
      const { datas } = await getTemplatePersonByUserId({
        userId: this.$store.getters.userId,
        platform: this.nextForm.platform || this.basicInfo.platform
      })
      this.templateData = datas
    },
    // 回复模板远程搜索
    async getContent() {
      const { datas } = await getTemplateContent({
        userId: this.$store.getters.userId,
        platform: this.nextForm.platform || this.basicInfo.platform,
        content: this.inputContent
      })
      this.contentData = datas
    },
    // 图片预览
    getSrcList(index, srcList) {
      let List = []
      List = srcList.map((item) => item.mediaURL)
      return List.slice(index).concat(List.slice(0, index))
    },
    // OrderInfo里面传一个事件过来，有两个地方，触发的那个地方已经注释
    getfocus(val) {
      this.$refs.input.focus()
      this.content = val
    },
    // 选择表情
    getBrow(index) {
      for (const i in this.faceList) {
        if (index + '' === i) {
          this.getBrowString = this.faceList[index]
          this.selectEmoji(this.getBrowString)
        }
      }
    },
    // 打开选择表情区域
    faceContent() {
      this.faceShow = !this.faceShow
      if (this.faceShow === true) {
        for (const i in appData) {
          this.faceList.push(appData[i].char)
        }
      } else {
        this.faceList = []
      }
    },
    async _getAmazonPagedList(data) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 平台账号站点bu
      const { platform = '', account = '', site = '', bu = '' } = JSON.parse(this.$route.query.form || '{}')
      this.nextForm = { platform, account, site, bu }
      if (this.$route.query.id) {
        const id = this.$route.query.id
        const { datas = {}, code } = await getMessageById(id).finally(() => {
          loading.close()
        })
        this.customerMessageList = []
        this.basicInfo = datas
        // this.workName = this.basicInfo.otherWorkingName ? this.basicInfo.otherWorkingName : ''
        // if (this.basicInfo.messageMarkLabelList == undefined || (this.basicInfo.messageMarkLabelList && this.basicInfo.messageMarkLabelList.length == 0)) {
        //   this.basicInfo.messageMarkLabelList = [{ markLabel: [] }]
        // }
        const { messageDetailVOList = [], orderList = [] } = datas
        this.OrderInfo = orderList
        this._getLogByMessageId({ messageId: this.basicInfo.id })
        this._getTemplatePersonByUserId()
        // this.getNotice()
        this.customerMessageList = messageDetailVOList || []
        // const recipient = this.customerMessageList[0] && this.customerMessageList[0].recipientId
        // console.log(this.customerMessageList[0].recipientId)
        // 处理对话框的信息在左边还是右边
        // 1:客户发的，2:客服回复的，3:系统自动回复的，4:草稿
        for (var i = 0; i < this.customerMessageList.length; i++) {
          const itemData = this.customerMessageList[i]
          itemData.senderType === 1
            ? (itemData.type = 1)
            : (itemData.senderType === 2 || itemData.senderType === 3)
              ? (itemData.type = 2)
              : ''
        }
        // 草稿
        const { body } = this.customerMessageList.find((item) => item.senderType === 4) || []
        body ? this.content = body.replace(/<br\>/g, '\r\n') : ''
        this.dialogVisible = true
        this.$nextTick(() => {
          this.scrollToBottom()
        })
        // 工单质检管理跳转过来的时候，不展示
        if (this.disabled) return
        this.basicInfo.isRead === 0 ? this.markAsReaded(1) : ''
        if (code === 0) {
          this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 1 })
          // 有其他人在处理，去下一封
          if (this.basicInfo.otherWorkingName) {
            if (this.basicInfo.otherWorkingName !== '') {
              this.$confirm(this.$t('title.alreadyexists') + this.basicInfo.otherWorkingName + this.$t('title.continueeply'), {
                confirmButtonText: this.$t('title.nextMessage'),
                cancelButtonText: this.$t('title.Continuetoreply')
              }).then(() => {
                this.handlenextMessage()
              }).catch(() => {
                // 这个工单有订单号，且这个订单号是亚马逊的，且这个订单号下有多个（超过1个）未完结的工单
                this.amazonHasMultipleOrder()
              })
            }
          } else {
            // 这个工单有订单号，且这个订单号是亚马逊的，且这个订单号下有多个（超过1个）未完结的工单
            this.amazonHasMultipleOrder()
          }
        }
      }
    },
    async _updateMessageServiceIsWorkingById(params) {
      await updateMessageServiceIsWorkingById(params)
      // const { code } = await updateMessageServiceIsWorkingById(params)
      // if (code === 0) {
      //   this.$message.success(code)
      // }
    },
    // 标记已经读了
    async markAsReaded(readed) {
      const { id } = this.basicInfo
      const { status } = await updateAmazonMessageReadById(id, readed)
      status === 0 ? this.$emit('refresh') : ''
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.singleHeight.scrollTop = this.$refs.singleHeight.scrollHeight
      })
    },
    // OrderInfo里面会调用
    async _getLogByMessageId(id) {
      const { datas } = await getLogByMessageId(id)
      this.noteLists = datas || []
    },
    keyDown(e) {
      const type = 1
      e = e || window.event
      e.ctrlKey && e.keyCode === 13 ? this.sendMsg(type) : ''
    },
    // 发送消息，type:1文字 2图片;obj:上传图片的参数
    async sendMsg(type, obj) {
      // if (!obj || !arr) {
      //   var text = this.content.replace(/<[^>]+>/g, '').trim()
      //   if (text === '') return this.$message.error(this.$t('tips.enterContent'))
      // }
      // 检查敏感词
      const { code, msg } = await checkSensitiveWord({ sensitiveWord: this.content, platform: this.basicInfo.platform, id: this.basicInfo?.id })
      if (code === 0) {
        try {
          if (type) {
            this.sendLoading = true
            const text = this.content.replace(/<[^>]+>/g, '')
            if (text === '') return this.$message.error(this.$t('tips.enterContent'))
            const obj = { content: text, type: 2, operatorName: this.$store.getters.name, createdTime: dayjs().format('YYYY-MM-DD HH:mm:ss'), mediaList: [] }
            text ? this.customerMessageList.push(obj) : ''
            this.$set(obj, 'body', text.replace(/(\r\n)|(\n)/g, '<br>'))
            this.content = ''
            this.scrollToBottom()
            const { account, id, messageDetailVOList } = this.basicInfo
            let List = []
            List = this.customerMessageList.filter((item) => item.type === 1)
            const params = Object.assign(
              {},
              {
                account,
                serviceMessageId: id,
                messageId: List[List.length - 1].messageId,
                messageStatus: 1,
                senderId: messageDetailVOList[0].recipientId,
                senderType: 2,
                subject: messageDetailVOList[0].subject,
                recipientId: process.env.NODE_ENV !== 'production' ? 'youshiqi17@gmail.com' : messageDetailVOList[0].senderId
              },
              { body: text.replace(/(\r\n)|(\n)/g, '<br>') }
            )
            const { code, msg } = await batchSendMessage(params)
            if (code === 0) {
              this.$message.success(msg)
            } else {
              this.$message.success(msg)
            }
            this.faceShow = false
            this.$emit('refresh')
            this.sendLoading = false
            this.checked === true ? this.handlenextMessage(1) : ''
          } else {
            const { code } = await batchSendMessage(obj)
            if (code === 0) {
              this.$message.success(this.$t('tips.Sent'))
              this.customerMessageList.push({
                mediaList: obj[0].messageMediaVOList
              })
              this.scrollToBottom()
            }
            this.faceShow = false
            this.$emit('refresh')
            this.sendLoading = false
            this.checked === true ? this.handlenextMessage(1) : ''
          }
        } finally {
          this.sendLoading = false
        }
      } else {
        this.$message.success(msg)
        this.content = ''
      }
    },
    // 暂不回复
    handlenextMessageStop() {
      this.handlenextMessage(null, 1)
    },
    // 自动下一封，data参数没用，stopType代表的就是暂不回复
    async handlenextMessage(data, stopType) {
      this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 0 })

      if (this.content !== '') {
        var text = this.content.replace(/<[^>]+>/g, '').trim()
        if (text === '') return this.$message.error(this.$t('tips.enterContent'))
        const { account, id, messageDetailVOList } = this.basicInfo
        let List = []
        List = this.customerMessageList.filter((item) => item.type === 1)
        const params = Object.assign(
          {},
          {
            account,
            serviceMessageId: id,
            messageId: List[List.length - 1].messageId,
            messageStatus: 3,
            senderId: messageDetailVOList[0].recipientId,
            senderType: 2,
            subject: messageDetailVOList[0].subject,
            recipientId: process.env.NODE_ENV !== 'production' ? 'youshiqi17@gmail.com' : messageDetailVOList[0].senderId
          },
          { body: text.replace(/(\r\n)|(\n)/g, '<br>') }
        )
        const res = await batchSendMessage(params)
        if (res === 0) {
          this.content = ''
        }
        // 如果当前工单是有草稿的，但是清空草稿还点了下一封，就得把这个工单置为不是草稿
      } else if (this.content === '' && this.basicInfo.messageStatus === 3) {
        const { account, id, messageDetailVOList } = this.basicInfo
        let List = []
        List = this.customerMessageList.filter((item) => item.type === 1)
        const params = Object.assign(
          {},
          {
            account,
            serviceMessageId: id,
            messageId: List[List.length - 1].messageId,
            messageStatus: 3,
            senderId: messageDetailVOList[0].recipientId,
            senderType: 2,
            subject: messageDetailVOList[0].subject,
            recipientId: process.env.NODE_ENV !== 'production' ? 'youshiqi17@gmail.com' : messageDetailVOList[0].senderId
          },
          { body: this.content.trim() }
        )
        const res = await batchSendMessage(params)
        if (res === 0) {
          this.content = ''
        }
      }
      const { platform, account, site, bu } = this.nextForm
      const form = JSON.parse(this.$route.query.form)
      form.id = this.basicInfo.id
      form.account = account
      form.site = site
      form.bu = bu
      form.platform = platform || this.basicInfo.platform
      form.messageStatus = this.basicInfo.messageStatus
      this.form.id = this.basicInfo.id
      form.isTempNoReply = 0
      if (stopType === 1) form.isTempNoReply = 1

      // 下一封
      const { datas, code, msg } = await getMessageInfoByCondition(form)

      if (stopType === 1) {
        this.$message.success(msg)
        if (code === 0) {
          setTimeout(() => {
            window.close()
          }, 2000)
        }
        return
      }

      this._getTemplatePersonByUserId()
      this.basicInfo = datas
      this.OrderInfo = datas.orderList
      this.customerMessageList = []
      this.customerMessageList = this.basicInfo.messageDetailVOList || []
      if (this.customerMessageList.length) {
        for (var i = 0; i < this.customerMessageList.length; i++) {
          const itemData = this.customerMessageList[i]
          itemData.senderType === 1
            ? (itemData.type = 1)
            : (itemData.senderType === 2 || itemData.senderType === 3)
              ? (itemData.type = 2)
              : ''
        }
      }

      // 关闭message
      this.messageInstance?.close()

      const { body } = this.customerMessageList.find(item => item.messageStatus === 3) || []
      body ? this.content = body : ''
      this.basicInfo.isRead === 0 ? this.markAsReaded(1) : ''
      this._getLogByMessageId({ messageId: this.basicInfo.id })
      if (code === 0) {
        this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 1 })
        if (this.basicInfo.otherWorkingName) {
          if (this.basicInfo.otherWorkingName !== '') {
            this.$confirm(this.$t('title.alreadyexists') + this.basicInfo.otherWorkingName + this.$t('title.continueeply'), {
              confirmButtonText: this.$t('title.nextMessage'),
              cancelButtonText: this.$t('title.Continuetoreply')
            }).then(() => {
              this.handlenextMessage()
            }).catch(() => {
              // 这个工单有订单号，且这个订单号是亚马逊的，且这个订单号下有多个（超过1个）未完结的工单
              this.amazonHasMultipleOrder()
            })
          }
        } else {
          // 这个工单有订单号，且这个订单号是亚马逊的，且这个订单号下有多个（超过1个）未完结的工单
          this.amazonHasMultipleOrder()
        }
      }
      this.content = ''
    },
    // 关闭页面
    // closeOrder() {
    //   this._updateMessageServiceIsWorkingById({ id: this.basicInfo.id, isWorking: 0 })
    //   this.$router.go(-1)
    // },
    // 完结工单
    async allsendMsg() {
      const { code, msg } = await batchUpdateMessageWorkStatus({
        ids: this.basicInfo.id,
        status: 99
      })
      code === 0 && this.$message.success(msg)
      if (code === 0) {
        // this.handlenextMessage(1)
        setTimeout(() => {
          window.close()
        }, 2000)
      }
    },
    // 查看全部消息
    // async allMeassge() {
    //   const obj = {}
    //   let buyerIds = ''
    //   if (this.basicInfo.platform === 'EBAY') {
    //     buyerIds = this.basicInfo.messageSender
    //   } else {
    //     this.customerMessageList = this.basicInfo.messageDetailVOList
    //     if (this.customerMessageList[0].senderId && this.customerMessageList[0].senderType === 1) {
    //       buyerIds = this.customerMessageList[0].senderId
    //     } else {
    //       buyerIds = this.basicInfo.messageSender
    //     }
    //   }
    //   obj.buyerId = buyerIds
    //   obj.platform = this.nextForm.platform || this.basicInfo.platform
    //   const { datas } = await getAllLogByMessageId(obj)
    //   this.customerMessageList = datas
    //   for (var i = 0; i < this.customerMessageList.length; i++) {
    //     const itemData = this.customerMessageList[i]
    //     itemData.senderType === 1
    //       ? (itemData.type = 1)
    //       : (itemData.senderType === 2 || itemData.senderType === 3)
    //         ? (itemData.type = 2)
    //         : ''
    //   }
    // },
    // 选择模板
    handleContent(val) {
      let str = val
      str = str.replace(/客户昵称/g, (this.basicInfo.orderList && this.basicInfo.orderList.length > 0 && this.basicInfo.orderList[0].buyerName) ? this.basicInfo.orderList[0].buyerName : this.basicInfo.messageSender)
      str = str.replace(/账号名称/g, this.basicInfo.account)
      str = str.replace(/客服名称/g, this.$store.getters.name)
      this.selectEmoji(str)
    },
    // 插入昵称
    async nikeName(val) {
      let str = val
      if (val === '客户昵称') {
        str = val.replace('客户昵称', (this.basicInfo.orderList && this.basicInfo.orderList.length > 0 && this.basicInfo.orderList[0].buyerName) ? this.basicInfo.orderList[0].buyerName : this.basicInfo.messageSender)
      } else if (val === '账号名称') {
        str = val.replace('账号名称', this.basicInfo.account)
      } else {
        str = val.replace('客服名称', this.$store.getters.name)
      }
      this.selectEmoji(str, 'nikeName')
    },
    selectEmoji(emoji, source) {
      var elInput = document.getElementById('textarea') // 根据id选择器选中对象
      var startPos = elInput.selectionStart // input a第0个字符到选中的字符
      var endPos = elInput.selectionEnd // 选中的字符到最后的字符
      if (startPos === undefined || endPos === undefined) return
      var txt = elInput.value
      // 将表情添加到选中的光标位置
      var result = txt.substring(0, startPos) + emoji + txt.substring(endPos)
      elInput.value = result// 赋值给input的value
      this.content = elInput.value
    },
    // 上传图片
    handleSuccess(res, file) {
      this.imageUrl = res.datas
      const { account, id, messageDetailVOList } = this.basicInfo
      let List = []
      List = this.customerMessageList.filter((item) => item.type === 1)
      const obj = {
        messageMediaVOList: [
          {
            mediaURL: this.imageUrl,
            mediaName: file.name
          }
        ],
        account,
        serviceMessageId: id,
        messageId: List[List.length - 1].messageId,
        messageStatus: 1,
        senderId: messageDetailVOList[0].recipientId,
        senderType: 2,
        subject: messageDetailVOList[0].subject,
        recipientId: process.env.NODE_ENV !== 'production' ? 'youshiqi17@gmail.com' : messageDetailVOList[0].senderId
      }
      const type = 0
      this.sendMsg(type, obj)
      this.$refs.upload.clearFiles()
    },
    // 限制图片大小
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 3
      if (!isLt5M) {
        this.$message.error(this.$t('tips.SnallerThan'))
      }
      return isLt5M
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-badge__content.is-fixed {
  position: absolute;
  top: 8px;
  right: 5px;
  -webkit-transform: translateY(-50%) translateX(100%);
  transform: translateY(-50%) translateX(100%);
}
.browBox {
  width: 100%;
  height: 130px;
  background: #e6e6e6;
  position: absolute;
  top: 29px;
  overflow: scroll;
  z-index: 999;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
    li {
      width: 7%;
      font-size: 20px;
      list-style: none;
      text-align: center;
    }
  }
}
.dialog {
  em {
    font-style: normal;
    margin-right: 40px;
  }
  .border {
    border-bottom: 1px solid #ccc;
  }
}
pre{
  white-space:pre-wrap;
  word-wrap:break-word;
  }
/deep/.cell {
  min-height: 40px !important;
  line-height: 40px !important;
  // position: relative !important;
  em {
    position: absolute;
    top: 0px;
    right: 10px;
    padding: 0 10px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: white;
    background-color: red;
    border-radius: 10px;
    font-style: normal;
  }
}
.wrap {
  width: 100%;
  border: 1px solid #ccc;
  ul {
    min-height: 40px;
    list-style: none;
    li {
      overflow: hidden;
      margin: 10px 0px 10px;
    }
    .left_word {
      div {
        float: left;
        background-color: rgb(255, 255, 255);
        padding: 5px 10px;
        max-width: 60%;
        overflow-x: auto !important;
        border: 1px solid #ccc;
        border-radius: 12px;
        font-size: 14px;
        color: #000;
        margin-left: 13px;
        position: relative;
        line-height: 24px;
        /deep/.el-image {
          border: none !important;
          width: 70px !important;
          height: 70px !important;
          margin-left: 0 !important;
          padding: 5px !important;
        }
      }
    }
    .Left_Word {
      span {
        float: left;
        background-color: rgb(255, 255, 255);
        padding: 5px 10px;
        max-width: 95%;
        overflow-x: auto !important;
        border: 1px solid #ccc;
        border-radius: 12px;
        font-size: 14px;
        color: #000;
        margin-left: 13px;
        position: relative;
        line-height: 24px;
      }
    }
    .right_word {
      img {
        width: 30px;
        height: 30px;
        float: right;
        margin-right: 20px;
      }
      span {
        float: right;
        background-color: rgb(255, 255, 255);
        padding: 5px 10px;
        max-width: 450px;
        border-radius: 12px;
        border: 1px solid #ccc;
        font-size: 14px;
        color: #000;
        margin-right: 13px;
        position: relative;
        line-height: 24px;
      }
    }
  }
  .main {
    height: 360px;
    overflow: auto;
    .talk_list {
      padding: 0px !important;
    }
  }
  .footer {
    position: relative;
    margin: 10px 5px;
  }
}
/deep/ .el-textarea__inner {
  min-height: 350px !important;
  line-height: 1 !important;
}
.unread {
  color: #333;
}
.read {
  color: #999;
}
.app-container {
  padding: 10px;
}
.el-table {
  /deep/ th {
    padding: 0;
  }
}

/deep/.el-table thead .cell {
  padding-top: 10px;
  line-height: 30px;
}
/deep/.el-icon-circle-close:before {
  content: "\e78d";
  color: #fff !important;
}
.active {
  color: rgb(218, 218, 218);
}
.addWidth{
  widows: 100%;
}
</style>

