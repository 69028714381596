<template>
  <div>
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1" title="">
        <template slot="title">
          <span style="font-size: 16px;font-weight: bold;margin-right: 30px;">{{ $t('title.orderinfo') }}</span>
          <span v-if="basicInfo.platform ==='AMAZON' ">
            <el-tooltip v-if="basicInfo.atoZStatus ===6" class="item" content="Granted" placement="top">
              <el-button plain size="mini" type="primary">A-to-Z</el-button>
            </el-tooltip>
            <el-tooltip v-if="basicInfo.atoZStatus ===5" class="item" content="Closed" placement="top">
              <el-button plain size="mini" type="success">A-to-Z</el-button>
            </el-tooltip>
            <el-tooltip v-if="basicInfo.atoZStatus === 4" class="item" content="Under Review" placement="top">
              <el-button plain size="mini" type="warning">A-to-Z</el-button>
            </el-tooltip>
            <el-tooltip v-if="basicInfo.atoZStatus === 3" class="item" content="Action Required" placement="top">
              <el-button plain size="mini" type="danger">A-to-Z</el-button>
            </el-tooltip>
            <el-tooltip v-if="basicInfo.atoZStatus === 7" class="item" content="UnKnow" placement="top">
              <el-button plain size="mini">A-to-Z</el-button>
            </el-tooltip>
          </span>
        </template>
        <div style="font-size: 14px;word-break: break-all;">
          <!-- 订单信息 -->
          <span style="font-weight: bold;color: #606266;line-height: 28px;">{{ $t("title.order") }}：</span>
          <span
            :style="{color:basicInfo.orderList && basicInfo.orderList[0] && basicInfo.orderList[0].orderTotal?'#606266':'#ff4949'}"
            style="cursor: pointer;font-weight: bold;"
            @click="jumpToOrderDetail"
          >{{ basicInfo.orderCoder }}</span>
          <el-button
            v-if="basicInfo.orderCoder"
            style="padding: 0px 0px 0px 10px;"
            type="text"
            @click="copyText(basicInfo.orderCoder, 0)"
          >{{ $t("title.copy") }}
          </el-button>
          <el-button
            v-if="!disabled"
            style="padding: 0px;"
            type="text"
            @click="showOrderEdit"
          >{{ $t("title.Edit") }}
          </el-button>

          <el-form v-if="basicInfo.orderList && basicInfo.orderList[0]" ref="form" class="orderInfo" label-position="left" label-width="120px">
            <el-form-item :label="$t('title.orderamount')+'：'">
              <template>
                <span>{{ basicInfo.orderList[0].orderTotal }}</span>
              </template>
            </el-form-item>
            <el-form-item :label="$t('title.Paymenttime')+'：'">
              <template>
                <span v-if="basicInfo.orderList[0].paidTime">{{ basicInfo.orderList[0].paidTime | dataFormat }}</span>
              </template>
            </el-form-item>
            <el-form-item :label="$t('title.Customerids')+'：'">
              <template>
                <span>{{ basicInfo.orderList.length > 0 && basicInfo.orderList[0].buyerName ? basicInfo.orderList[0].buyerName : basicInfo.messageSender }}</span>
              </template>
            </el-form-item>
            <el-form-item :label="$t('title.Recipientaddress')+'：'">
              <template>
                <span>{{ basicInfo.orderList[0].buyerDest }}</span>
              </template>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
      <el-collapse-item name="2" title="">
        <template slot="title">
          <span style="font-size: 16px;font-weight: bold;">{{ $t('title.Productinformation') }}</span>
        </template>
        <!-- listing信息 -->
        <div v-if="basicInfo.listingId || basicInfo.orderCoder">
          <div v-if="basicInfo.listingId && !basicInfo.orderList">
            <span v-if="basicInfo.platform ==='AMAZON'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://www.amazon.com/gp/product/${basicInfo.listingId}`" style="color: #3296fa" target="_blank">https://www.amazon.com/gp/product/{{ basicInfo.listingId }}</a></span>
            <span v-else-if="basicInfo.platform ==='EBAY'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://www.ebay.com/itm/XXX/${basicInfo.listingId}`" style="color: #3296fa" target="_blank">https://www.ebay.com/itm/XXX/{{ basicInfo.listingId }}</a></span>
            <span v-else-if="basicInfo.platform ==='WALMART'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://www.walmart.com/ip/product/${basicInfo.listingId}`" style="color: #3296fa" target="_blank">https://www.walmart.com/ip/product/{{ basicInfo.listingId }}</a></span>
            <span v-else-if="basicInfo.platform ==='STANDWEBSITE'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://${accountStr}${basicInfo.style}`" style="color: #3296fa" target="_blank">https://{{ accountStr }}{{ basicInfo.style }}</a></span>
            <span v-else-if="basicInfo.platform ==='TIKTOK'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://shop.tiktok.com/view/product/${basicInfo.listingId}`" style="color: #3296fa" target="_blank">https://shop.tiktok.com/view/product/{{ basicInfo.listingId }}</a></span>
            <span v-else />
            <br>
          </div>
          <div v-for="(item, index) in basicInfo.orderList" :key="index">
            <span v-if="basicInfo.platform ==='AMAZON'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://www.amazon.${item.sitesuffix}/gp/product/${item.listingId}`" style="color: #3296fa" target="_blank">https://www.amazon.{{ item.sitesuffix }}/gp/product/{{ item.listingId }}</a></span>
            <span v-else-if="basicInfo.platform ==='EBAY'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://www.ebay.com/itm/XXX/${item.listingId}`" style="color: #3296fa" target="_blank">https://www.ebay.com/itm/XXX/{{ item.listingId }}</a></span>
            <span v-else-if="basicInfo.platform ==='WALMART'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://www.walmart.com/ip/product/${item.listingId}`" style="color: #3296fa" target="_blank">https://www.walmart.com/ip/product/{{ item.listingId }}</a></span>
            <span v-else-if="basicInfo.platform ==='STANDWEBSITE'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://${item.accountSuffix}${item.style}`" style="color: #3296fa" target="_blank">https://{{ item.accountSuffix }}{{ item.style }}</a></span>
            <span v-else-if="basicInfo.platform ==='TIKTOK'" style="font-weight: bold;color: #606266;">Listing：<a :href="`https://shop.tiktok.com/view/product/${item.listingId}`" style="color: #3296fa" target="_blank">https://shop.tiktok.com/view/product/{{ item.listingId }}</a></span>
            <span v-else />
            <br>
            <el-form ref="form" class="orderInfo" label-position="left" label-width="100px">
              <el-form-item label="BU：">
                <template>
                  <span>{{ item.bu }}</span>
                </template>
              </el-form-item>
              <el-form-item label="MSKU：">
                <template>
                  <span>{{ item.sku }}</span>
                </template>
              </el-form-item>
              <el-form-item :label="$t('title.Productrank')+'：'">
                <template>
                  <span>{{ item.position }}</span>
                </template>
              </el-form-item>
              <el-form-item label="Style：">
                <template>
                  <span>{{ item.style }}</span>
                </template>
              </el-form-item>
              <el-form-item label="Color：">
                <template>
                  <span>{{ item.color }}</span>
                </template>
              </el-form-item>
              <el-form-item label="Size：">
                <template>
                  <span>{{ item.size }}</span>
                </template>
              </el-form-item>
              <el-form-item :label="$t('title.Quantity')+'：'">
                <template>
                  <span>{{ item.number }}</span>
                </template>
              </el-form-item>
              <el-form-item :label="$t('title.FulfillmentMethod')+'：'">
                <template>
                  <span>{{ item.shippingType }}</span>
                </template>
              </el-form-item>
              <el-form-item :label="$t('title.Trackingnumber')+'：'">
                <template>
                  <span v-if="item.trackingNumber">
                    <a :href="`https://t.17track.net/zh-cn#nums=${item.trackingNumber}`" style="color: #3296fa" target="_blank">{{ item.trackingNumber }}</a>
                  </span>
                </template>
              </el-form-item>
              <el-form-item :label="$t('title.RMA')+'：'">
                <template>
                  <span v-for="(item, index) in item.rmaList" :key="index" style="cursor: pointer;color: #3296fa;display: block;" @click="jumpToRMA(item)">{{ item.rmaNumber }}</span>
                </template>
              </el-form-item>
              <!-- 关联补单 -->
              <el-form-item :label="$t('title.RelatedOrder')+'：'">
                <template>
                  <span v-for="(item, index) in item.replaceOrderNumberList" :key="index" style="cursor: pointer;color: #3296fa;display: block;" @click="handleToOrderDetail(item)">{{ item.replaceOrderNumber }}</span>
                </template>
              </el-form-item>
              <el-form-item :label="$t('title.Shippingtime')+'：'">
                <template>
                  <span>{{ item.shippingTime?item.shippingTime:$t('title.Notshipped') }}</span>
                </template>
              </el-form-item>
              <el-form-item :label="$t('title.ProblemMentioned')+'：'">
                <el-row>
                  <el-col :span="18">
                    <el-cascader
                      v-model="item.messageMarkLabelList[0]"
                      :disabled="disabled"
                      :options="options"
                      :props="{ multiple: true, value: 'id', label: language === 'en-US' ? 'labelNameEN' : 'labelName', children: 'childNodes', }"
                      clearable
                      filterable
                      show-all-levels
                      @change="getLabels"
                    />
                    <!-- <el-cascader
                        v-else
                        clearable
                        :value="''"
                        :options="options"
                        :props="{ multiple: true, value: 'id', label: 'labelName', children: 'childNodes', }"
                        @change="getLabels"
                      /> -->
                  </el-col>
                  <el-col v-if="!disabled" :span="6" style="text-align: right;">
                    <el-button size="small" style="margin-left:10px" type="primary" @click="tagSure(false, item.id,item.messageMarkLabelList[0])">{{ $t("title.sure") }}</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
            <!-- 标签 -->
          </div>
        </div>
        <!-- 单独标签 -->
        <el-form v-if="!basicInfo.orderList" label-position="left" label-width="100px">
          <el-form-item :label="$t('title.customerID')+'：'">
            <template>
              <span>{{ basicInfo.messageSender }}</span>
            </template>
          </el-form-item>
          <el-form-item v-if="!basicInfo.orderList" :label="$t('title.MessageID')+'：'">
            <template>
              <span>{{ basicInfo.messageDetailVOList && basicInfo.messageDetailVOList[0] && basicInfo.messageDetailVOList[0].senderId ? basicInfo.messageDetailVOList[0].senderId : '' }}</span>
            </template>
          </el-form-item>
          <el-form-item v-if="!basicInfo.orderList" :label="$t('title.ProblemMentioned')+'：'">
            <el-row>
              <el-col :span="18">
                <el-cascader
                  v-model="markLables"
                  :disabled="disabled"
                  :options="options"
                  :props="{ multiple: true, value: 'id', label:language === 'en-US' ? 'labelNameEN' : 'labelName', children: 'childNodes', }"
                  clearable
                  filterable
                  show-all-levels
                  @change="getLabels"
                />
              </el-col>
              <el-col :span="6" style="text-align: right;">
                <el-button v-if="!disabled" size="small" style="margin-left:10px" type="primary" @click="tagSure(false, '',markLables)">{{ $t("title.sure") }}</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-collapse-item>
      <el-collapse-item name="3" title="">
        <template slot="title">
          <span style="font-size: 16px;font-weight: bold;">{{ $t('page.operate') }}</span>
        </template>
        <el-form class="demo-form-inline" label-position="left" label-width="100px">
          <!-- 备注消息 -->
          <el-form-item :label="$t('title.note')+'：'">
            <el-row>
              <el-col :span="18">
                <el-input v-model="remark" :disabled="disabled" :placeholder="$t('page.inputPlaceholder')" />
              </el-col>
              <el-col :span="6" style="text-align: right;">
                <el-button v-if="!disabled" :loading="remarkLoading" size="small" style="margin-left:10px" type="primary" @click="addNoticeSure">{{ $t("title.sure") }}</el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item :label="$t('title.Note')+'：'">
            {{ newRemark }}
          </el-form-item>
          <!-- 流转工单 -->
          <el-form-item :label="$t('title.Transferto')+'：'">
            <el-row>
              <el-col :span="18">
                <el-select v-model="workFrom" :disabled="disabled" clearable filterable value-key="id" @change="handleChange">
                  <el-option v-for="item in buOptions" :key="item.id" :label="item.userName" :value="item" />
                </el-select>
              </el-col>
              <el-col v-if="!disabled" :span="6" style="text-align: right;">
                <el-button size="small" type="primary" @click="circulation">{{ $t("title.sure") }}</el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <!-- <p>{{ newWorkFrom }}</p> -->
          <!-- 提醒 -->
          <el-form-item :label="`${$t('title.Remind')}(${$t('title.day')})：`" style="margin-top:10px">
            <el-input v-model="radio" :disabled="disabled" :placeholder="$t('page.inputPlaceholder')" @blur="changeDateSure()" @input="radioInput" />
          </el-form-item>
        </el-form>
        <!--  操作记录 -->
        <el-table :data="noteList" :header-cell-style="{background:'#fafafa',height:'40px',padding:'0px'}" border max-height="200" style="width: 100%;margin-top: 10px;">
          <el-table-column :label="$t('title.content')" prop="logContent" />
          <el-table-column :label="$t('title.operator')" prop="userName" />
          <el-table-column :label="$t('title.time')" prop="logTime" />
        </el-table>
      </el-collapse-item>
    </el-collapse>

    <!-- 提醒 -->
    <!-- <el-form :inline="true" class="mt-2">
      <el-form-item label="提醒">
        <el-row>
          <el-col :span="22">
            <el-radio-group v-model="radio" size="small">
              <el-radio-button label="1">1天后</el-radio-button>
              <el-radio-button label="2">2天后</el-radio-button>
              <el-radio-button label="3">3天后</el-radio-button>
              <el-radio-button label="7">7天后</el-radio-button>
              <el-radio-button @click.native="definetimedialogVisible = true">自定义</el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col :span="2">
            <el-button size="small" type="primary" style="margin-left: -8px" @click="changeDateSure()">确定</el-button>
          </el-col>
        </el-row>

        <el-row v-if="basicInfo.isWarn === 1">
          <el-tag closable @close="closeTag(basicInfo.id, 0, setDate(0))">{{
            basicInfo.warnTime
          }}</el-tag>
        </el-row>
      </el-form-item>
    </el-form> -->

    <!-- 分类 -->
    <!-- <div>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item :label="$t('title.classify')">
          <el-select
            v-model="type"
            clearable
            :placeholder="$t('page.selectPlaceholder')"
            @change="_gettemplatelistbywhere"
          >
            <el-option :label="$t('title.OrderAll')" value="" />
            <el-option v-for="item in typeData" :key="item.id" :label="item.typeName" :value="item.typeName" />
          </el-select>
        </el-form-item>
      </el-form>
      <div
        :style="{
          height: !basicInfo.orderList.length ? '250px' : '220px',
          overflow: 'auto',
        }"
      >
        <el-row v-for="(item, index) in tableData" :key="index" type="flex" justify="space-between" class="border">
          <el-col :span="14" style="padding: 10px 0">
            <el-tooltip class="item" effect="dark" :content="item.content" placement="top-start">
              <span>{{ item.title }}</span>
            </el-tooltip>
          </el-col>
          <el-col :span="6" :offset="6">
            <el-button type="text" @click="copyText(item.content, 1)">{{
              $t("title.copy")
            }}</el-button>
          </el-col>
        </el-row>
      </div>
    </div> -->
    <!-- 自定义提醒时间设置 -->
    <!-- <el-dialog
      title="自定义日期"
      :visible.sync="definetimedialogVisible"
      width="40%"
      :append-to-body="true"
    >
      <el-date-picker
        v-model="value1"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="datetime"
        placeholder="选择日期时间"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="definetimedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="definetimeSure">确 定</el-button>
      </span>
    </el-dialog> -->

    <!-- 编辑订单号 -->
    <OrderEditDialog
      :basic-info="basicInfo"
      :order-edit-visible.sync="orderEditVisible"
      @handleRefresh="handleRefresh"
    />
  </div>
</template>

<script>
import {
  allBU,
  batchMarkLabelInsert,
  batchUpdateMessageWarnById,
  getLogByMessageId,
  insertCirculation,
  messagesGetLabel,
  updatemailremark
} from '@/api/service-management'
// import { validURL } from '@/utils/validate'
// import { deepClone } from '@/utils'
// import store from '@/store'
import Cookies from 'js-cookie'
import OrderEditDialog from './OrderEditDialog.vue'
import { orderInfo } from '@/api/omsorder'
import { urlMap } from '../config'

export default {
  name: 'OrderInfo',
  components: {
    OrderEditDialog
  },
  props: {
    dialogVisible: {
      type: Boolean
    },
    // 没有用到，用的是basicInfo，因为basicInfo就是basicInfo的orderList
    orderInfo: {
      type: Array,
      default: () => ([])
    },
    basicInfo: {
      type: Object,
      default: () => ({})
    },
    // 没用到
    form: {
      type: Object,
      default: () => ({})
    },
    // 没用到
    status: {
      type: Number,
      default: 0
    },
    noteLists: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      label: [],
      value1: '',
      warnTime: '',
      definetimedialogVisible: false,
      arr: [],
      tagForm: {
        tags: []
      },
      radio: '',
      options: [],
      flag: true,
      remark: '',
      tableData: [],
      typeData: [],
      type: '',
      threadId: '',
      expandedKeys: [],
      showRow: true, // 切换显示input
      workFrom: '',
      buOptions: [],
      beUserId: '',
      beUserName: '',
      noteList: [],
      newRemark: '',
      newWorkFrom: '',
      markLables: [],
      tiemMarkLables: [],
      accountStr: '',
      language: '',
      remarkLoading: false,

      orderEditVisible: false,

      activeNames: ['1', '2', '3'],

      // 工单质检管理跳转过来的时候，页面只供展示不可操作
      disabled: Boolean(this.$route.query.disabled) || false
    }
  },
  watch: {
    'basicInfo'(val) {
      if (val) {
        // 处理单独标签，也就是orderList不存在时候的标签
        this.markLables = val.messageMarkLabelList && val.messageMarkLabelList.length > 0 ? JSON.parse(JSON.stringify(val.messageMarkLabelList[0].markLabel)) : []
        if (!val?.orderCoder && val?.orderList && val?.orderList.length) {
          this.markLables = val.orderList[0].messageMarkLabelList && val.orderList[0].messageMarkLabelList.length > 0 ? JSON.parse(JSON.stringify(val.orderList[0].messageMarkLabelList[0].markLabel)) : []
        }
        // 处理标签，也就是listingId和orderCoder存在时候的标签
        if (val.orderList) {
          val.orderList.forEach((v, i) => {
            v.index = i
            v.messageMarkLabelList = [v.messageMarkLabelList && v.messageMarkLabelList.length > 0 ? JSON.parse(JSON.stringify(v.messageMarkLabelList[0].markLabel)) : []]
          })
          this.basicInfo.orderList && (this.basicInfo.orderList = val.orderList)
        }

        // 平台为STANDWEBSITE并且listingId存在basicInfo.orderList不存在的跳转url
        if (val.account) {
          if (val.account === 'Bruno Marc') {
            this.accountStr = 'www.brunomarcshoes.com/goods/index?spu='
          } else if (val.account === 'Dream Pairs') {
            this.accountStr = 'www.dreampairs.com/goods/index?spu='
          } else if (val.account === 'Nortiv8') {
            this.accountStr = 'www.nortiv8shoes.com/goods/index?spu='
          } else if (val.account === 'BURUDANI') {
            this.accountStr = 'www.goburudani.com/goods/index?spu='
          } else if (val.account === 'Bruno Marc_New') {
            this.accountStr = 'www.brunomarcshoes.com/goods/index?spu='
          } else if (val.account === 'Dream Pairs_New') {
            this.accountStr = 'www.dreampairs.com/goods/index?spu='
          } else if (val.account === 'Nortiv8_New') {
            this.accountStr = 'www.nortiv8shoes.com/goods/index?spu='
          } else if (val.account === 'BURUDANI_New') {
            this.accountStr = 'www.goburudani.com/goods/index?spu='
          }
          // 平台为STANDWEBSITE并且basicInfo.orderList存在的跳转url
          val.orderList?.map(e => {
            if (val.account === 'Bruno Marc') {
              e.accountSuffix = 'www.brunomarcshoes.com/goods/index?spu='
            } else if (val.account === 'Dream Pairs') {
              e.accountSuffix = 'www.dreampairs.com/goods/index?spu='
            } else if (val.account === 'Nortiv8') {
              e.accountSuffix = 'www.nortiv8shoes.com/goods/index?spu='
            } else if (val.account === 'BURUDANI') {
              e.accountSuffix = 'www.goburudani.com/goods/index?spu='
            } else if (val.account === 'Bruno Marc_New') {
              e.accountSuffix = 'www.brunomarcshoes.com/goods/index?spu='
            } else if (val.account === 'Dream Pairs_New') {
              e.accountSuffix = 'www.dreampairs.com/goods/index?spu='
            } else if (val.account === 'Nortiv8_New') {
              e.accountSuffix = 'www.nortiv8shoes.com/goods/index?spu='
            } else if (val.account === 'BURUDANI_New') {
              e.accountSuffix = 'www.goburudani.com/goods/index?spu='
            }
          })
          this.$forceUpdate()
        }
        // 平台为AMAZON并且basicInfo.orderList存在的跳转url
        if (val.site) {
          val.orderList?.map(e => {
            e.sitesuffix = urlMap[val.site.toLowerCase()]
          })
          this.$forceUpdate()
        }
        // 所有标签
        this._messagesGetLabel()
        this._allBU()
        // 操作记录
        this._getLogByMessageId({ messageId: this.basicInfo.id })
      }
    },
    // 没用到，后面页面自己发了一次
    'noteLists'(val) {
      if (val) {
        this.noteList = val || []
        this.newRemark = ''
        const typeText = '备注'
        const arr = []
        val.map(e => {
          if (e.logType === typeText) arr.push(e)
        })
        const maxObj = this.getMaxArr(arr, typeText)
        if (maxObj) {
          typeText === '备注' ? this.newRemark = maxObj.logContent : ''
        }
      }
    },
    deep: true
  },
  mounted() {
    this.language = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    // 这里没用，上面监听basicInfo已经触发了
    this._messagesGetLabel()
    this._allBU()
  },
  methods: {
    jumpToRMA(item) {
      window.open(`${process.env.VUE_APP_MIRMILES_API}/after-sales/audit-detail?rmaNumber=${item.rmaId}`)
    },
    handleToOrderDetail(row) {
      const url = this.$router.resolve({
        path: '/order-manage/order-center-manage/details',
        query: { 'id': row.id, 'orderCreateUtcDate': row.orderCreateUtcDate }
      })
      window.open(url.href, '_blank')
    },
    async jumpToOrderDetail() {
      // 用的订单管理列表的接口
      const { data, success } = await orderInfo({ currPage: 1, pageSize: 10, orderNumber: this.basicInfo.orderCoder })
      if (success) {
        if (data?.list && data?.list[0]?.id) {
          const url = this.$router.resolve({
            path: '/order-manage/order-center-manage/details',
            query: { id: data?.list[0]?.id }
          })
          window.open(url.href, '_blank')
        }
      }
    },
    // 编辑订单之后刷新页面
    handleRefresh() {
      this.$emit('handleRefresh')
    },
    showOrderEdit() {
      this.orderEditVisible = true
    },
    // 获取所有bu
    async _allBU() {
      const { datas } = await allBU()
      this.buOptions = datas
    },
    // 获取操作记录
    async _getLogByMessageId(id, type) {
      const { datas } = await getLogByMessageId(id)
      this.noteList = datas
      const typeText = '备注'
      const arr = []
      this.noteList.map(e => {
        if (e.logType === typeText) arr.push(e)
      })
      const maxObj = this.getMaxArr(arr, typeText)
      if (maxObj) {
        typeText === '备注' ? this.newRemark = maxObj.logContent : ''
      } else {
        this.newRemark = ''
        this.newWorkFrom = ''
      }
    },
    getMaxArr(arr) {
      // 最大的值
      const maxValue = Math.max.apply(Math, arr.map((item, index) => {
        return item.id
      }))
      // 判断是否有值
      if (isNaN(maxValue)) return null
      // 最大的对象
      for (var keys in arr) {
        if (arr[keys].id === maxValue) {
          return arr[keys]
        }
      }
    },
    // 没用
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 打标签
    async tagSure(bool, id, val) {
      // 有的时候就算orderList存在，但是由于没有订单号，导致打标签上传的的id为空
      if (this.basicInfo && this.basicInfo.orderList && this.basicInfo.orderList.length && !id) {
        id = this.basicInfo.orderList[0].id
      }
      let obj = {}
      const arr = []
      const lableIds = this.arr.length > 0 ? this.arr : [val]
      const flag = lableIds.find(item => item.length === 0)
      if (lableIds.length && !flag) {
        for (var i = 0; i < lableIds.length; i++) {
          for (var j = 0; j < lableIds[i].length; j++) {
            obj = Object.assign({}, { orderId: id, labelId: lableIds[i][j], messageId: this.basicInfo.id })
            arr.push(obj)
          }
        }
      } else {
        obj = Object.assign({}, { orderId: id, labelId: [], messageId: this.basicInfo.id })
        arr.push(obj)
      }
      const { code, msg } = await batchMarkLabelInsert(bool, arr)
      code === 0 && this.$message.success(msg)
    },
    // 获取所有标签
    async _messagesGetLabel() {
      const { datas } = await messagesGetLabel(false)
      this.options = datas
    },
    // 复制
    copyText(value, FLAG) {
      var input = document.createElement('input')
      input.value = value
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.$message.success(this.$t('tips.Copied'))
      FLAG === 1 && this.$emit('getfocus', value)
    },
    // 选择标签的时候给arr赋值，就是绑定的值
    getLabels(val) {
      this.arr = []
      this.arr.push(val)
      this.arr.filter(item => item.length !== 0)
    },
    // 流转工单
    handleChange(val) {
      this.beUserId = val.id
      this.beUserName = val.userName
    },
    // 流转工单
    async circulation() {
      if (this.workFrom !== '') {
        this.parmas = { messageId: this.basicInfo.id, workFrom: this.$store.getters.name, workFromId: this.$store.getters.userId, workNumber: this.basicInfo.workNumber, workTo: this.beUserName, workToId: this.beUserId }
        const { code, msg } = await insertCirculation(this.parmas)
        code === 0 && this.$message.success(msg)
        if (code === 0) {
          this.workFrom = ''
          const type = 2
          this._getLogByMessageId({ messageId: this.basicInfo.id }, type)
        }
      } else {
        this.$message.error('请选择需要流转的人员')
      }
    },
    // batchUpdateMessageWarnById接口被删了，这里的功能应该不支持了
    // 设置提醒时间
    async changeDateSure() {
      if (this.radio === '') {
        return
      }
      if (Number(this.radio) > 30) {
        this.$message.error(this.$t('tips.LesserThan'))
        return
      } // Number(this.radio) === 1 ? this.warnTime = this.setDate(1) : Number(this.radio) === 2 ? this.warnTime = this.setDate(2) : Number(this.radio) === 3 ? this.warnTime = this.setDate(3) : Number(this.radio) === 7 ? this.warnTime = this.setDate(7) : ''
      this.warnTime = this.setDate(Number(this.radio))
      const { code, msg } = await batchUpdateMessageWarnById(this.basicInfo.id, 1, this.warnTime)
      code === 0 && this.$message.success(msg)
      if (code === 0) {
        this.radio = ''
        this._getLogByMessageId({ messageId: this.basicInfo.id })
      }
    },
    setDate(n) {
      var day3 = new Date()
      var day4 = new Date()
      day3.setTime(day3.getTime() + n * 24 * 60 * 60 * 1000)
      var s3 = day3.getFullYear() + '-' + (day3.getMonth() + 1) + '-' + day3.getDate() + ' ' + day4.getHours() + ':' + day4.getMinutes() + ':' + day4.getSeconds()
      return s3
    },
    // async closeTag(id, status, time) {
    //   const { code, msg } = await batchUpdateMessageWarnById(id, status, time)
    //   code === 0 && this.$message.success(msg)
    //   this.basicInfo.isWarn = 0
    // },
    // async definetimeSure() {
    //   const { code, msg } = await batchUpdateMessageWarnById(this.basicInfo.id, 1, this.value1)
    //   code === 0 && this.$message.success(msg)
    //   this.$set(this.basicInfo, 'isWarn', 1)
    //   this.$set(this.basicInfo, 'warnTime', this.value1)
    //   this.definetimedialogVisible = false
    //   this.value1 = ''
    // },
    // 备注信息
    async addNoticeSure() {
      try {
        this.remarkLoading = true
        if (this.remark === '') {
          this.$message.error(this.$t('tips.notEmpty'))
          return
        }
        this.parmas = { messageId: this.basicInfo.id, remark: this.remark, operatorName: this.$store.getters.name }
        const { code, msg } = await updatemailremark(this.parmas)
        code === 0 && this.$message.success(msg)
        if (code === 0) {
          this.remark = ''
          const type = 1
          this._getLogByMessageId({ messageId: this.basicInfo.id }, type)
        }
      } catch (error) {
        this.remarkLoading = false
      } finally {
        this.remarkLoading = false
      }
    },
    // 验证提醒格式
    radioInput(e) {
      const RegExp = /^[1-9]\d*$/
      if (!RegExp.test(e)) {
        this.radio = ''
      }
      if (parseInt(e) > 30) {
        this.radio = ''
        this.$message.error(this.$t('tips.GreaterThan30'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item {
  margin-bottom: 0px !important;
}
/deep/.el-form-item--medium {
  .el-form-item__content,
  .el-form-item__label {
    line-height: 28px !important;
  }
}
</style>
