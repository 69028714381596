export const urlMap = {
  'us': 'com',
  'ca': 'ca',
  'mx': 'com.mx',
  'au': 'com.au',
  'gb': 'co.uk',
  'de': 'de',
  'fr': 'fr',
  'es': 'es',
  'it': 'it',
  'nl': 'nl',
  'se': 'se'
}
