<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="orderEditVisible"
    append-to-body
    width="60%"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      label-width="auto"
      size="small"
    >
      <el-row :span="24" :gutter="20">
        <el-col :span="24">
          <el-form-item :label="$t('title.ordernumber')+'：'" prop="orderCode">
            <el-input v-model.trim="form.orderCode" clearable :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelEdit">{{ $t('title.cancel') }}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="submitEdit">{{ $t('title.confirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mailUpdateOrderCoder } from '@/api/service-management'
export default {
  props: {
    orderEditVisible: {
      type: Boolean,
      default: false
    },
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        orderCode: ''
      },
      formRules: {
        orderCode: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }
        ]
      },
      submitLoading: false
    }
  },
  methods: {
    cancelEdit() {
      this.$refs.form.resetFields()
      this.$emit('update:orderEditVisible', false)
    },
    async submitEdit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.submitLoading = true
          const { msg = '', success = false } = await mailUpdateOrderCoder({ id: this.basicInfo?.id, platform: this.basicInfo?.platform, orderCoder: this.form.orderCode }).finally(() => {
            this.submitLoading = false
          })
          if (success) {
            this.$message.success(msg)
            this.$router.replace({
              query: {
                id: this.basicInfo?.id,
                form: this.$route.query.form
              }
            }, () => {
              this.$emit('handleRefresh')
              this.cancelEdit()
            }, () => {
              this.$emit('handleRefresh')
              this.cancelEdit()
            })
          } else {
            this.$message.error(msg)
          }
        }
      })
    }
  }
}
</script>
